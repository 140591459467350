import VueRouter, { RouteConfig } from 'vue-router'
import PageNotFound from '@/pages/404.vue'
import Policy from '@/pages/policy.vue'
import Thankyou from '@/pages/thankyou.vue'

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'Policy',
    component: Policy
  },
  {
    path: 'thankyou',
    name: 'Thankyou',
    component: Thankyou
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
