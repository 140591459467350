



















































































































































import { Component, Vue } from 'vue-property-decorator'
import PrivacyProvider from '@/resources/PrivacyProvider'

@Component
export default class Policy extends Vue {
  private accept = false

  private status = 'Submit'

  private loading = false

  get queryToken (): string | (string | null)[] {
    return this.$route.query.token || ''
  }

  mounted (): void {
    if (this.queryToken === '') {
      this.$router.push({ name: 'PageNotFound' })
    }
  }

  async onSubmit (): Promise<void> {
    try {
      this.loading = true
      this.status = 'Processing...'

      await PrivacyProvider.acceptPrivacyPolicy(`${this.queryToken}`)
      this.$router.push({ name: 'Thankyou' })
    } catch (error) {
      console.error('Privacy Accept', error)
      // eslint-disable-next-line no-alert
      alert('Something went wrong. Please contact admin or try again later.')
    } finally {
      this.loading = false
      this.status = 'Submit'
    }
  }
}
