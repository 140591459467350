import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './routers/index'
import '@/assets/css/fonts.css'
import '@/assets/css/app.css'

Vue.config.productionTip = false
Vue.use(VueRouter)

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
